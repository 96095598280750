<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <slot name="table-header"/>
      </thead>
      <tbody v-if="hasResults">
        <slot name="table-body"/>
      </tbody>
      <tfoot v-if="hasResults">
        <slot name="table-footer"/>
      </tfoot>
    </table>
    <p class="mt10 text-center text-red" v-if="!hasResults">
      <strong>No Results Found</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    hasResults: { type: Boolean, required: true }
  }
}
</script>
