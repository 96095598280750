<script>
import Badge from '@/views/components/simple/Badge'
import DataTable from '@/views/components/data/DataTable'
import StatsColumn from '@/views/components/stats/StatsColumn'

export default {
  name: 'CampaignStatsBase',
  components: {
    Badge,
    DataTable,
    StatsColumn
  },
  props: {
    values: { type: Array, required: true },
    statsLoaded: { type: Boolean, default: false }
  }
}
</script>
