import campaignSettings from '@/modules/strubs/campaignSettings'
import campaignLabel from '@/modules/strubs/campaignLabel'
import contactList from '@/modules/strubs/contactList'
import api from '@/modules/strubs/api'
import offer from '@/modules/strubs/offer'
import template from '@/modules/strubs/template'
import domain from '@/modules/strubs/domain'
import senderCategory from '@/modules/strubs/senderCategory'

export default {
  id: 0,
  name: '',
  status: 0,
  status_text: '',
  created_at: '',
  updated_at: '',
  starts_at: '',
  finished_at: '',
  settings: campaignSettings,
  labels: [campaignLabel],
  lists: [contactList],
  apis: [api],
  offers: [offer],
  templates: [template],
  domains: [domain],
  sender_categories: [senderCategory],
  metadata: {
    actions: {
      cancel: false,
      pause: false,
      resume: false
    },
    editable: {
      this: false
    }
  }
}
