import stats from '@/modules/strubs/stats'

export default {
  id: 0,
  name: '',
  lifetime: '',
  active: false,
  compare_clicker_data: false,
  url: '',
  redirect_url: '',
  created_at: '',
  updated_at: '',
  campaigns_count: 0,
  stats: stats
}
