<template>
  <data-table :has-results="values.length > 0">
    <template slot="table-header">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th class="text-center">Sending Rate</th>
        <th>Stats</th>
        <th class="text-right">Created At</th>
      </tr>
    </template>
    <template slot="table-body">
      <tr v-for="api in values" :key="`campaign-stats-apis-tr-${api.id}`">
        <td>{{ api.id }}</td>
        <td>
          <router-link :to="{ name: 'apis-single', params: { id: api.id } }">{{ api.name }}</router-link>
        </td>
        <td class="text-center">
          <badge title="TPS">{{ api.sending_rate }}</badge>
        </td>
        <td>
          <stats-column :stats="api.stats" :loaded="statsLoaded"/>
        </td>
        <td class="text-right">{{ api.created_at }}</td>
      </tr>
    </template>
  </data-table>
</template>

<script>
import Base from '@/views/pages/campaigns/partials/stats/Base'

export default {
  name: 'CampaignStatsOffers',
  extends: Base
}
</script>
