import contactList from '@/modules/strubs/contactList'
import contactListFileReport from '@/modules/strubs/contactListFileReport'

const file = {
  id: 0,
  original_name: '',
  stored_path: '',
  rows_parsed: 0,
  override: false,
  list: contactList,
  report: contactListFileReport
}

export default file
