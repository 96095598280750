<template>
  <div>
    <portal to="page-top-title">Campaign #{{ id }}</portal>
    <portal to="page-top-right">
      <btn v-if="campaign.metadata.actions.cancel" icon="fa-ban" color="danger" @click.stop.native="confirmAction(campaign.id, 'cancel')" text>Cancel</btn>
      <btn v-if="campaign.metadata.actions.pause" icon="fa-pause" color="warning" @click.stop.native="confirmAction(campaign.id, 'pause')" text>Pause</btn>
      <btn v-if="campaign.metadata.actions.resume" icon="fa-play" color="success" @click.stop.native="confirmAction(campaign.id, 'resume')" text>Resume</btn>
      <btn :route="{ name: 'campaigns-duplicate', params: { id: campaign.id } }" icon="fa-copy" text>Duplicate</btn>
      <btn v-if="campaign.metadata.editable.this" :route="{ name: 'campaigns-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box title="Statistics" :open="campaignReqDone" :loading="!campaignReqDone">
      <template slot="content" v-if="campaign.id">
        <tabs-wrapper :name="`campaign-${id}`" :tabs="statsTabs">
          <campaign-stats-lists slot="lists" :values="campaign.lists" :stats-loaded="campaignRelStatsLoaded"/>
          <campaign-stats-offers slot="offers" :values="campaign.offers" :stats-loaded="campaignRelStatsLoaded"/>
          <campaign-stats-templates slot="templates" :values="campaign.templates" :stats-loaded="campaignRelStatsLoaded"/>
          <campaign-stats-apis slot="apis" :values="campaign.apis" :stats-loaded="campaignRelStatsLoaded"/>
          <campaign-stats-domains slot="domains" :values="campaign.domains" :stats-loaded="campaignRelStatsLoaded"/>
          <campaign-stats-senderCats slot="senderCats" :values="campaign.sender_categories" :stats-loaded="campaignRelStatsLoaded"/>
        </tabs-wrapper>
      </template>
    </box>

    <box-single :url="`/campaigns/${id}`" loader data-prop="campaign" resp-prop="data" @request-after="requestAfter">
      <template slot="content">
        <row>
          <column-info title="Name">{{ campaign.name }}</column-info>
          <column-info title="Type">{{ campaign.type_text }}</column-info>
          <column-info :title="`Labels (${campaign.labels.length})`">
            <em class="text-info" v-if="!campaign.labels.length">None</em>
            <div class="td-rows comma" v-else>
              <div class="td-row" v-for="label in campaign.labels" :key="`label-${label.id}`">
                <router-link :to="{ name: 'campaigns-labels-single', params: { id: label.id } }">{{ label.name }}</router-link>
              </div>
            </div>
          </column-info>
          <column-info title="Dates">
            <div class="td-rows columns divider">
              <div class="td-row">
                <span class="left">Created At:</span>
                <span class="right">{{ campaign.created_at }}</span>
              </div>
              <div class="td-row">
                <span class="left">Starts At:</span>
                <span class="right">
                  <nullable :value="campaign.starts_at"/>
                </span>
              </div>
              <div class="td-row">
                <span class="left">Finished At:</span>
                <span class="right">
                  <nullable :value="campaign.finished_at"/>
                </span>
              </div>
              <div class="td-row">
                <span class="left">Time Passed:</span>
                <span class="right">
                  <time-passed :start="new Date(campaign.finished_at)"/>
                </span>
              </div>
            </div>
          </column-info>
        </row>
        <row>
          <column-info title="Openers Only">
            <badge-active :value="campaign.settings.openers_only"/>
          </column-info>
          <column-info title="Clickers Only">
            <badge-active :value="campaign.settings.clickers_only"/>
          </column-info>
        </row>
        <row>
          <column-info title="Status">
            <badge-status :value="campaign.status_text"/>
          </column-info>
          <column-info title="Messages">
            <stats-messages :stats="campaignStats"/>
          </column-info>
          <column-info title="Clicks">
            <stats-clicks :stats="campaignStats"/>
          </column-info>
          <column-info title="Revenues">
            <stats-revenues :stats="campaignStats"/>
          </column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import TabsWrapper from '@/views/components/tabs/TabsWrapper'
import CampaignStatsLists from '@/views/pages/campaigns/partials/stats/Lists'
import CampaignStatsOffers from '@/views/pages/campaigns/partials/stats/Offers'
import CampaignStatsTemplates from '@/views/pages/campaigns/partials/stats/Templates'
import CampaignStatsApis from '@/views/pages/campaigns/partials/stats/Apis'
import CampaignStatsDomains from '@/views/pages/campaigns/partials/stats/Domains'
import CampaignStatsSenderCats from '@/views/pages/campaigns/partials/stats/SenderCats'
import Btn from '@/views/components/simple/Btn'
import Nullable from '@/views/components/simple/Nullable'
import TimePassed from '@/views/components/simple/TimePassed'
import BadgeStatus from '@/views/components/simple/BadgeStatus'
import StatsMessages from '@/views/components/stats/Messages'
import StatsClicks from '@/views/components/stats/Clicks'
import StatsRevenues from '@/views/components/stats/Revenues'
import Action from '@/modules/utils/Action'
import campaign from '@/modules/strubs/campaign'
import BadgeActive from '@/views/components/simple/BadgeActive'

export default {
  name: 'CampaignsSingle',
  metaInfo () {
    return { title: 'Campaign #' + this.id }
  },
  components: {
    StatsRevenues,
    StatsClicks,
    StatsMessages,
    BoxSingle,
    TabsWrapper,
    CampaignStatsLists,
    CampaignStatsOffers,
    CampaignStatsTemplates,
    CampaignStatsApis,
    CampaignStatsDomains,
    CampaignStatsSenderCats,
    Btn,
    Nullable,
    TimePassed,
    BadgeStatus,
    BadgeActive
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      campaign: campaign,
      campaignReqDone: false,
      campaignRelStatsLoaded: false,
      campaignStats: {},
      statsTabs: [
        { slot: 'lists', title: 'Lists' },
        { slot: 'offers', title: 'Offers' },
        { slot: 'templates', title: 'Templates' },
        { slot: 'apis', title: 'APIs' },
        { slot: 'domains', title: 'Domains' },
        { slot: 'senderCats', title: 'Sender Categories' }
      ]
    }
  },
  methods: {
    requestAfter () {
      this.campaignReqDone = true

      if (!this.campaign.id) return

      this.loadCampaignStats()
      this.loadRelationsStats()
    },
    loadCampaignStats () {
      this.$http
        .get('/campaigns/stats', { params: { ids: [this.id] } })
        .then((res) => {
          const { data: { data } } = res
          const id = Number(this.id)

          this.campaignStats = data.find((stats) => stats.id === id)
        })
    },
    loadRelationsStats () {
      this.$http
        .get(`/campaigns/${this.id}/stats-relations`)
        .then((res) => {
          const { data: { data } } = res

          for (const key in (data || {})) {
            if (key in this.campaign) {
              for (const stats of data[key]) {
                const rel = this.campaign[key].find((rel) => rel.id === stats.id)

                if (rel) rel.stats = stats
              }
            }
          }

          this.campaignRelStatsLoaded = true
        })
    },
    confirmAction (id, action) {
      Action.confirm({
        id: id,
        action: action,
        name: 'Campaign',
        route: '/campaigns/:id/action',
        entity: this.campaign
      })
    }
  }
}
</script>
