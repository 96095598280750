import company from '@/modules/strubs/company'
import userRole from '@/modules/strubs/userRole'

export default {
  id: 0,
  name: '',
  email: '',
  owner: false,
  active: false,
  tfa_enabled: false,
  created_at: '',
  updated_at: '',
  unblock_on: '',
  pswd_updated_at: '',
  company: company,
  roles: [userRole],
  metadata: {
    editable: {
      this: false
    }
  }
}
