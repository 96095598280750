import contactListCategory from '@/modules/strubs/contactListCategory'
import contactListFile from '@/modules/strubs/contactListFile'
import country from '@/modules/strubs/country'
import user from '@/modules/strubs/user'
import stats from '@/modules/strubs/stats'

const list = {
  id: 0,
  name: '',
  price: 0,
  custom_id: '',
  active: false,
  live_list: false,
  status: 0,
  status_text: '',
  endpoint: '',
  contacts_count_total: 0,
  contacts_count_active: 0,
  contacts_count_inactive: 0,
  updated_at: '',
  created_at: '',
  category: contactListCategory,
  file: contactListFile,
  country: country,
  user: user,
  stats: stats,
  metadata: {
    editable: {
      this: false,
      active: false,
      category: false
    }
  }
}

export default list
