export default {
  id: 0,
  messages_count: 0,
  messages_sent: 0,
  messages_failed: 0,
  messages_undelivered: 0,
  messages_blacklisted: 0,
  clicks_count: 0,
  revenues_count: 0,
  revenues_amount: 0
}
