import categoryGroup from '@/modules/strubs/categoryGroup'

const category = {
  id: 0,
  name: '',
  lists_count_total: 0,
  lists_count_active: 0,
  lists_count_inactive: 0,
  created_at: '',
  updated_at: '',
  group: categoryGroup
}

export default category
