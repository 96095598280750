import ip from '@/modules/strubs/domainIp'

export default {
  id: 0,
  name: '',
  used: 0,
  active: false,
  ssl_active: false,
  status: 0,
  status_text: '',
  created_at: '',
  updated_at: '',
  ip: ip
}
