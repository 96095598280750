<template>
  <div class="nav-tabs-custom no-box-padding">
    <ul class="nav nav-tabs">
      <template v-for="tab in tabs">
        <li :key="`${name}-tabs-nav-${tab.slot}`" :class="{ 'active': isActive(tab) }">
          <a :href="`#${tab.slot}`" @click.prevent="navChange(tab)">{{ tab.title }}</a>
        </li>
      </template>
    </ul>
    <div class="tab-content no-padding">
      <div class="tab-pane active" :id="activeTab">
        <slot :name="activeTab"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsWrapper',
  props: {
    name: { type: String, required: true },
    tabs: { type: Array, required: true }
  },
  data () {
    return {
      activeTab: this.initActiveTab()
    }
  },
  methods: {
    initActiveTab () {
      return this.tabs.length
        ? (this.tabs.find((t) => t.active) || this.tabs[0]).slot
        : undefined
    },
    isActive (tab) { return this.activeTab === tab.slot },
    navChange (tab) { this.activeTab = tab.slot }
  }
}
</script>
