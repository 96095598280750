import templateCategory from '@/modules/strubs/templateCategory'
import subjectCategory from '@/modules/strubs/subjectCategory'
import stats from '@/modules/strubs/stats'

export default {
  id: 0,
  name: '',
  content: '',
  has_code_url: false,
  active: false,
  type: 0,
  type_text: '',
  content_css: '',
  content_html: '',
  content_compiled: '',
  created_at: '',
  updated_at: '',
  category: templateCategory,
  subjectCategories: [subjectCategory],
  stats: stats,
  metadata: {
    editable: {
      this: false,
      active: false
    }
  }
}
