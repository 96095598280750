import contactListFile from '@/modules/strubs/contactListFile'

const report = {
  id: 0,
  report: [],
  created_at: '',
  file: contactListFile
}

export default report
