<template>
  <data-table :has-results="values.length > 0">
    <template slot="table-header">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th class="text-center">Compare Clicker Data</th>
        <th>Stats</th>
        <th class="text-right">Created At</th>
      </tr>
    </template>
    <template slot="table-body">
      <tr v-for="offer in values" :key="`campaign-stats-offers-tr-${offer.id}`">
        <td>{{ offer.id }}</td>
        <td>
          <router-link :to="{ name: 'offers-single', params: { id: offer.id } }">{{ offer.name }}</router-link>
        </td>
        <td class="text-center">
          <badge-active :value="offer.compare_clicker_data"/>
        </td>
        <td>
          <stats-column :stats="offer.stats" :loaded="statsLoaded"/>
        </td>
        <td class="text-right">{{ offer.created_at }}</td>
      </tr>
    </template>
  </data-table>
</template>

<script>
import Base from '@/views/pages/campaigns/partials/stats/Base'
import BadgeActive from '@/views/components/simple/BadgeActive'

export default {
  name: 'CampaignStatsOffers',
  extends: Base,
  components: { BadgeActive }
}
</script>
