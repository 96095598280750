<template>
  <data-table :has-results="values.length > 0">
    <template slot="table-header">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Contacts</th>
        <th>Stats</th>
        <th class="text-right">Created At</th>
      </tr>
    </template>
    <template slot="table-body">
      <tr v-for="list in values" :key="`campaign-stats-lists-tr-${list.id}`">
        <td>{{ list.id }}</td>
        <td>
          <router-link :to="{ name: 'contactLists-single', params: { id: list.id } }">{{ list.name }}</router-link>
        </td>
        <td>
          <badge title="Total">{{ list.contacts_count_total }}</badge>
          <badge title="Active" color="green">{{ list.contacts_count_active }}</badge>
          <badge title="Inactive" color="red">{{ list.contacts_count_inactive }}</badge>
        </td>
        <td>
          <stats-column :stats="list.stats" :loaded="statsLoaded"/>
        </td>
        <td class="text-right">{{ list.created_at }}</td>
      </tr>
    </template>
  </data-table>
</template>

<script>
import Base from '@/views/pages/campaigns/partials/stats/Base'

export default {
  name: 'CampaignStatsLists',
  extends: Base
}
</script>
