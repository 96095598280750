const label = {
  id: 0,
  name: '',
  description: '',
  active: false,
  campaigns_count: 0,
  created_at: '',
  updated_at: ''
}

export default label
