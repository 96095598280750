<template>
  <data-table :has-results="values.length > 0">
    <template slot="table-header">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Senders</th>
        <th>Stats</th>
        <th class="text-right">Created At</th>
      </tr>
    </template>
    <template slot="table-body">
      <tr v-for="category in values" :key="`campaign-stats-senderCats-tr-${category.id}`">
        <td>{{ category.id }}</td>
        <td>
          <router-link :to="{ name: 'senders-categories-single', params: { id: category.id } }">{{ category.name }}</router-link>
        </td>
        <td>
          <badge title="Total">{{ category.senders_count_total }}</badge>
          <badge title="Active" color="green">{{ category.senders_count_active }}</badge>
          <badge title="Inactive" color="red">{{ category.senders_count_inactive }}</badge>
        </td>
        <td>
          <stats-column :stats="category.stats" :loaded="statsLoaded"/>
        </td>
        <td class="text-right">{{ category.created_at }}</td>
      </tr>
    </template>
  </data-table>
</template>

<script>
import Base from '@/views/pages/campaigns/partials/stats/Base'

export default {
  name: 'CampaignStatsOffers',
  extends: Base
}
</script>
