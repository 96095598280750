import company from '@/modules/strubs/company'

const provider = {
  id: 0,
  name: '',
  active: false,
  credentials: [],
  apis_count_total: 0,
  apis_count_active: 0,
  apis_count_inactive: 0,
  created_at: '',
  updated_at: '',
  company: company
}

export default provider
