import campaign from '@/modules/strubs/campaign'

const settings = {
  id: 0,
  order_messages_by: 0,
  first_name_default: '',
  created_at: '',
  updated_at: '',
  campaign: campaign,
  clickers_only: false,
  openers_only: false
}

export default settings
