import apiProvider from '@/modules/strubs/apiProvider'
import company from '@/modules/strubs/company'

export default {
  id: 0,
  name: '',
  active: false,
  credentials: [],
  data: [],
  sending_rate: 0,
  created_at: '',
  updated_at: '',
  provider: apiProvider,
  company: company
}
